:root {
   --background: white;
   --secondary: rgb(147, 147, 147);
   --color:black;
   --color2:rgb(78, 78, 78);
   --link_color: navy;
   --gradient: linear-gradient(90.21deg, rgba(246, 255, 202, 0.941) -5.91%,rgba(203, 191, 255, 0.541) 111.58% );
}

[data-theme="dark"] {
  --background: #282c34;
  --secondary: rgb(0, 0, 0);
   --color:white;
   --color2:rgb(218, 218, 218);
   --link_color: cyan;
   /* --gradient:linear-gradient(90.21deg, rgba(170, 54, 124, 0.5) -5.91%, rgba(74, 47, 189, 0.5) 111.58%); */
   --gradient:gray;

}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.thumbs{
  display: none;
}

::-webkit-scrollbar {
  width: 12px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px rgb(170, 54, 124);
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgb(89, 70, 171);
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: rgba(59, 28, 194, 0.5);
}

.testimonial-card .card-up {
  height: 120px;
  overflow: hidden;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}
.testimonial-card .avatar {
  width: 110px;
  margin-top: -60px;
  overflow: hidden;
  border: 3px solid #fff;
  border-radius: 50%;
}